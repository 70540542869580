<template>
  <v-expansion-panels multiple class="rounded-card">
    <v-expansion-panel
      active-class="rounded-card"
      v-for="(duvida, index) in duvidas"
      :key="index"
    >
      <v-expansion-panel-header disable-icon-rotate>
        <span class="headline font-weight-bold text--primary--color pt-3 pb-3">
          {{ duvida.duvida }}
        </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="large-text mb-4">
          {{ duvida.resposta }}
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  data() {
    return {
      duvidas: [
        {
          duvida: 'Qual a proposta da Proxpect?',
          resposta: 'Somos um marketplace para empresas, ou seja, somente CNPJs podem fazer negócios (vender ou comprar) na plataforma. Pelo lado do fornecedor, oportunizamos eles estarem vendendo e divulgando seus produtos online, sem investimento inicial. Já para o comprador, damos agilidade e facilidade para o setor de compras na compra de insumos, produtos e matérias-primas mitigando o número de cotações.',
        },
        {
          duvida: 'Como e onde é enviado o boleto ou chave pix para pagamento dentro do marketplace da Proxpect?',
          resposta: 'O pagamento é processado pelo fornecedor. Portanto, você receberá tanto o boleto como a chave pix para pagamento pelo e-mail cadastrado em seu usuário ou no chat dentro da compra na plataforma (poderá ser anexado pelo fornecedor). Caso seja de sua preferência, no chat dentro da venda você pode informar outro e-mail para envios de boleto e notas fiscais.',
        },
        {
          duvida: 'Como e onde é enviado a ordem de compra dentro do marketplace da Proxpect?',
          resposta: 'Você poderá enviar a ordem de compra no momento da finalização do pedido ou no chat dentro da compra na plataforma (como anexo). Muitas empresas usam o próprio registro de compra da Proxpect como uma ordem de compra, uma vez que se tem todos os dados referente ao pedido realizado.',
        },
        {
          duvida: 'Como funciona a entrega dos produtos comprados pelo marketplace da Proxpect?',
          resposta: 'A entrega dos produtos comprados dentro do marketplace da Proxpect podem se dar de 3 (três) maneiras: frete grátis (CIF), frete com taxa (CIF) e retirar na loja (FOB).\n\nTanto o frete grátis (CIF) quanto o frete com taxa (CIF) significam que o fornecedor irá levar o produto para sua empresa. Temos fretes grátis para diversas cidades e regiões e muitas empresas oferecem frete grátis a partir de um determinado valor de compra.\n\nPara alguns produtos, é necessário fazer a retirada no local indicado pelo fornecedor. Caso tenha urgência para o produto, você também pode optar por retirar na loja.',
        },
        {
          duvida: 'Como funciona o prazo de pagamento dentro do marketplace da Proxpect?',
          resposta: 'O prazo de pagamento é estabelecido e cadastrado conforme cada fornecedor. Os prazos podem mudar de fornecedor para fornecedor nos seguintes casos:\n\n- Políticas internas de preço e prazo de pagamento;\n- Valor e volume das compras;\n- Análise de crédito de empresas compradoras.',
        },
        {
          duvida: 'Existe custo para acessar o marketplace da Proxpect?',
          resposta: 'Você não precisa pagar nada. Basta fazer um usuário, usufruir de mais de 40 mil ofertas ativas e comprar de maneira rápida, eficiente e com preços competitivos.\n\nNão existem metas para volume de compras por empresa ou comprador e nem limite de usuários compradores por empresa.',
        },
        {
          duvida: 'Consigo ver quem é o fornecedor que está vendendo os produtos no marketplace da Proxpect?',
          resposta: 'Você consegue ter acesso a essa informação após fazer login com seu usuário do marketplace.',
        },
        {
          duvida: 'Consigo me comunicar com o fornecedor ou comprador no marketplace da Proxpect?',
          resposta: 'Você consegue se comunicar com o fornecedor. Para isto existem dois pontos de contato: as dúvidas e perguntas nas próprias ofertas de produtos e  o chat dentro da compra realizada.\n\n',
        },
        {
          duvida: 'O que fazer quando o meu pedido não chega no prazo correto?',
          resposta: 'Você pode entrar em contato com o nosso suporte no telefone (48) 98439-4452.\nFicaremos felizes em atender e ajudar vocês.',
        },
        {
          duvida: 'O que fazer quando o produto entregue não é igual ao comprado?',
          resposta: 'Você pode entrar em contato com o nosso suporte no telefone (48) 98439-4452.\nFicaremos felizes em atender e ajudar vocês.',
        },
        {
          duvida: 'O que fazer quando não for efetuado o pagamento como acordado?',
          resposta: 'Você pode entrar em contato com o nosso suporte no telefone (48) 98439-4452.\nFicaremos felizes em atender e ajudar vocês.',
        },
      ],
    };
  },
};
</script>
