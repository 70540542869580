<template>
  <div>
    <v-container class="mkt-content-container mt-10">
      <v-row class="body-2 mb-3">
        <v-col cols="12">
          <router-link to="/home">
            Home
          </router-link>
          >
          <span>
            Perguntas frequentes
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-10">
        <v-col cols="12">
          <span class="display-1 text--primary--color font-weight-bold">
            Perguntas frequentes
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col cols="12">
          <px-faq-items/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FAQItems from './FaqItems.vue';

export default {
  components: {
    pxFaqItems: FAQItems,
  },
};
</script>
